import axios from 'axios'
import { config } from '../../assets/config/config'

const apiUrl = config.api.url

const getProjectSummary  = ({recruiter_id,page=0,limit=5,title='',status, projectType='' }) => {
    return axios.get(`${apiUrl}/v1/recruiter-project/recruiter-getting/project-summary?page=${page+1}&limit=${limit}&recruiterId=${recruiter_id}&title=${title}&status=${status}&type=${projectType}`).then(resp => resp.data)
}

const getProjectAuditionType  = () => {
    return axios.get(`${apiUrl}/v1/project-type-audition/get-all`).then(resp => resp.data)
}

const closeProject =(id ,payload) =>{
    return axios.patch(`${apiUrl}/v1/recruiter-project/${id}`, payload).then((response) => response.data)
}

const projectAbuseReport = (payload) =>{
    return axios.post(`${apiUrl}/v1/project-abuse-report`, payload).then(resp => resp.data)
}

const closeProjectReason = () => {
    return axios.get(`${apiUrl}/v1/report-reason/reportProjectList`).then(resp => resp.data)
}

export const DashboardService = {
    getProjectSummary,
    getProjectAuditionType,
    closeProject,
    projectAbuseReport,
    closeProjectReason
}