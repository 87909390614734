import React, { lazy, useContext, useEffect, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import "../HomePage/CheeseBurger.css";
import { Link } from "react-router-dom";
import Logo from "../assets/img/Logo.svg";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const Sidebar = () => {
  const location = useLocation();
  const history = useHistory();
  const path = location.pathname;
  const { user } = useContext(AuthContext);

  var TalentType = 1;
  var RecruiterType = 2;

  const [isMenuOpen, setMenuOpen] = useState(false);
  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };
  // Listen for location changes and close the menu
  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  return !user && path !== "/talents" && path !== "/recruiters" ? (
    <Menu left isOpen={isMenuOpen} onStateChange={handleStateChange}>
      <div className="topmobilelogo">
        <Link to={"/"} className="logoImage">
          <img src={Logo} alt="CastingBell" />
        </Link>
      </div>
      <ul>
        <li className="text-gray-600 hover:text-blue-600">
          <Link to="/recruiters" target="_blank" className="headerTags">
            I’m Recruiting
          </Link>
        </li>
        <li className="text-gray-600 hover:text-blue-600">
          <Link to="/talents" target="_blank" className="headerTags">
            I’m the Talent
          </Link>
        </li>
        {/* <li className="text-gray-600 hover:text-blue-600">
          <Link to="" className="headerTags">
            Blog
          </Link>
        </li> */}
      </ul>
    </Menu>
  ) : user &&
    user?.user_type === RecruiterType ? (
    <Menu left isOpen={isMenuOpen} onStateChange={handleStateChange}>
      <div className="topmobilelogo">
        <Link to={"/"} className="logoImage">
          <img src={Logo} alt="CastingBell" />
        </Link>
      </div>
      <ul>
        {user?.user_type === RecruiterType && (
          <li>
            <Link
              to="/app/my-projects"
              className="headerTags"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              My Projects
            </Link>
          </li>
        )}
        {user?.user_type === RecruiterType && (
          <li>
            <Link
              to="/app/my-job-postings"
              className="headerTags"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              My Job Postings
            </Link>
          </li>
        )}

        {user?.user_type === RecruiterType && (
          <li>
            <Link
              to="/find-talents"
              className="headerTags"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              Find Talents
            </Link>
          </li>
        )}
             {user?.user_type === RecruiterType && (
          <li>
            <Link
              to="/app/messages"
              className="headerTags"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
             Messages
            </Link>
          </li>
        )}
      </ul>
    </Menu>
  ) : !path.includes("/app/talent/create") &&
    !path.includes("app/dashboard") &&
    user &&
    user?.user_type === TalentType &&  user?.talenter?.is_profile_creation_completed === true ? (
    <Menu left isOpen={isMenuOpen} onStateChange={handleStateChange}>
      <div className="topmobilelogo">
        <Link to={"/"} className="logoImage">
          <img src={Logo} alt="CastingBell" />
        </Link>
      </div>
      <ul>
        {user?.user_type === TalentType &&
          user?.talenter?.is_profile_creation_completed && (
            <li
              className="headerText cursor-pointer"
              onClick={() => history.push("/app/openings/open-projects")}
            >
              <span className="">My Jobs</span>
            </li>
          )}

        {user?.user_type === TalentType &&
          user?.talenter?.is_profile_creation_completed && (
            <li
              className="headerText cursor-pointer"
              onClick={() => history.push("/find-jobs")}
            >
              <span className="">Find Jobs</span>
            </li>
          )}
           {user?.user_type === TalentType &&
          user?.talenter?.is_profile_creation_completed && (
            <li
              className="headerText cursor-pointer"
              onClick={() => history.push("/app/messages")}
            >
              <span className="">Messages</span>
            </li>
          )}
      </ul>
    </Menu>
  ) : null;
};

export default Sidebar;
