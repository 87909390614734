import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import NotificationsNoneTwoToneIcon from "@mui/icons-material/NotificationsNoneTwoTone";
import { Badge } from "@mui/material";

import ReactHtmlParser from "react-html-parser";

import "../../pages/TalenterDashBoard/TalenterDashBoard.css";
import { useHistory } from "react-router-dom";
import { notificationService } from "../../services";
import { AuthContext } from "../../context/AuthContext";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "@windmill/react-ui";
import Button from "@mui/material/Button";

export default function NotificationDropDown({
  DropDownData,
  isNewMenu,
  setIsNewMenu,
  handleUpdateData,
  HandleMarkasRead,
  newNotificationCount,
  setNotificationCount,
}) {
  const { user } = React.useContext(AuthContext);
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [isSchedulePageOpen, setIsSchedulePageOpen] = React.useState(false);
  const [modal, setModalValue] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [id, setId] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");

  const handleClick = (event) => {
    document.body.classList.add("modal-open");
    setAnchorEl(event.currentTarget);
    handleUpdateData();
  };
  const handleClose = () => {
    document.body.classList.remove("modal-open");
    setAnchorEl(null);
    setIsNewMenu(false);
    notificationService
      .getNotificationCountByuserID(user?.user_id)
      .then((resp) => {
        if (resp.data.notificationCount) {
          setNotificationCount(resp.data.notificationCount);
        } else {
          setNotificationCount(0);
        }
      });
  };

  function closeModals() {
    setIsSchedulePageOpen(false);
    setModalValue(false);
  }

  React.useEffect(() => {
    if (open) {
      notificationService.updateNotificationCount(user?.user_id);
    }
  }, [open, user?.user_id]);

  const buttonStyles = {
    backgroundColor: "#9B26B6",
    color: "#fff",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    border: "none",
    borderRadius: "4px",
    marginTop: "10px",
    fontWeight: "600",
  };
  const buttonStylesGrey = {
    backgroundColor: "#979797",
    color: "#fff",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    border: "none",
    borderRadius: "4px",
    marginTop: "10px",
    fontWeight: "600",
  };

  const handleButtonClick = (message, id, start_date, end_date) => {
    // Your button click logic here
    console.log("Button clicked!", message);
    setMessage(ReactHtmlParser(message));
    setId(id);
    setStartDate(start_date);
    setEndDate(end_date);
    setIsSchedulePageOpen(true);
  };

  const available = () => {
    setIsSchedulePageOpen(false);
    setModalValue(true);

    var payload = {
      isTalentUnavailable: true,
      start_date: startDate,
      end_date: endDate,
    };
    var payloadData = {
      isAvailableYesorNo: true,
    };

    notificationService
      .updateAvailabilityButton(id, payloadData)
      .then((resp) => {
        if (resp) {
          handleUpdateData();
        }
      });

    notificationService.updateavailability(id, payload).then((resp) => {
      console.log("88", resp);
    });
  };

  const notAvailable = () => {
    var payload = {
      isTalentUnavailable: false,
      start_date: startDate,
      end_date: endDate,
    };
    var payloadData = {
      isAvailableYesorNo: false,
    };

    notificationService
      .updateAvailabilityButton(id, payloadData)
      .then((resp) => {
        if (resp) {
          handleUpdateData();
        }
      });
    notificationService.updateavailability(id, payload).then((resp) => {
      setIsSchedulePageOpen(false);
      setModalValue(true);
    });
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          marginLeft: "0 !important",
        }}
      >
        {/* <Tooltip title="Account settings"> */}
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Badge
            color="secondary"
            variant="dot"
            invisible={!isNewMenu}
            id="notification_badge"
          >
            <NotificationsNoneTwoToneIcon
              sx={{ color: "#9B26B6" }}
              fontSize="medium"
            />
          </Badge>
        </IconButton>
        {/* </Tooltip> */}
      </Box>
      <Menu
        className="notificatMenulist"
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        // disableAutoFocusItem={true}
      >
        {DropDownData?.map((each) => {
          return (
            <div key={each.id} className={"notification_container"}>
              <MenuItem
                className={`${!each.status && "BoldText"} contentText`}
                style={{ opacity: 1 }}
                // onClick={() => HandleMarkasRead(each.id)}
                onMouseDown={(e) => {
                  HandleMarkasRead(each.id);
                }}
              >
                <ListItemIcon>
                  <NotificationsNoneTwoToneIcon
                    sx={{ color: "#9B26B6" }}
                    fontSize="medium"
                  />
                </ListItemIcon>
                <div>
                  {ReactHtmlParser(each.message)}
                  {each.isButtonClick == true &&
                    (each.isAvailableYesorNo === null ? (
                      <button
                        style={buttonStyles}
                        onClick={() =>
                          handleButtonClick(
                            each.message,
                            each.available_id,
                            each.available_start_date,
                            each.available_end_date
                          )
                        }
                      >
                        Click me
                      </button>
                    ) : (
                      ""
                    ))}
                </div>
              </MenuItem>
              <Divider />
            </div>
          );
        })}
        <MenuItem
          className="BoldText contentText"
          onClick={() => {
            if (user?.user_type == 1) {
              history.push("/app/talent/dashboard/notification");
            }
            if (user?.user_type == 2) {
              history.push("/app/recruiter/dashboard/notification");
            }
          }}
        >
          See All Notifications{" "}
          {newNotificationCount > 0 ? `(${newNotificationCount} New)` : ""}
        </MenuItem>
      </Menu>

      {
        <Modal
          isOpen={isSchedulePageOpen}
          onClose={closeModals}
          className="modal_popup talent_module_modal"
        >
          <div className="modal_range">
            <div className="bring_talent">
              <h2>Talent Unlocked</h2>
            </div>
            <ModalBody className="modal_body ">
              <p className="message_para"> {message}</p>
            </ModalBody>
          </div>
          <div className="select_button modal_select part_size">
            <Button
              variant="contained"
              className="show_but show_butBlack"
              style={{ backgroundColor: "black", color: "white",border:"1px solid black" }}
              onClick={() => {
                notAvailable();
              }}
            >
              Sorry, not available
            </Button>

            <Button
              variant="contained"
              className="show_but show_hovor_button"
              onClick={() => {
                available();
              }}
            >
              Yes, available
            </Button>
          </div>
        </Modal>
      }

      {
        <Modal
          isOpen={modal}
          onClose={closeModals}
          className="modal_popup talent_module_modal"
        >
          <div className="modal_range">
            <div className="bring_talent">
              <h2>Confirmation Sent</h2>
            </div>
            <ModalBody className="modal_body">
              <p className="message_para message_para_outer">
                {" "}
                Your preference has been sent to the recruiter.
              </p>
            </ModalBody>
            <div className="select_button select_button_1 modal_select">
              <Button
                variant="contained"
                className="show_but show_hovor_button"
                onClick={() => {
                  closeModals();
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      }
    </React.Fragment>
  );
}
