import React, { useState, useMemo, useEffect, useCallback } from "react";
import moment from "moment";
import axios from "axios";
import { config } from "../assets/config/config";
import ThemedSuspense from "../components/ThemedSuspense";
import { notificationService, userServices } from "../services";
import { CometChat } from "@cometchat-pro/chat";
import io from "socket.io-client";

const apiUrl = config.api.url;

// create context
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [dependencyData, setDependency] = useState(null);
  const [signup, setSignup] = useState(null);
  const [isSignUpPopup, setIsSignUpPopup] = useState(false);
  const [isSignInPopup, setIsSignInPopup] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [isJobapply, setIsJobapply] = useState(false);

  const [isProfileFreezed, setIsProfileFreezed] = useState(false);
  const [signInTabs, setSignInTabs] = useState(null);
  const [isNewNotificationTiggered, setIsNewNotificationTiggered] =
    useState(false);

  const [refreshAvailableDates, setRefreshAvailableDates] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);


  const GetSignUpDependency = async () => {
    // /v1/getallsignupdependency
    return axios.get(`${apiUrl}/v1/get-all-masterData`).then((res) => {
      if (res) {
        setDependency(res.data);
      }
      return res;
    });
  };

  const refreshEditPage = useCallback((user_id) => {
    return axios
      .get(`${apiUrl}/v1/user/refresh-edit-profile/${user_id}`)
      .then((response) => {
        if (response.data.user_type == 1 || response.data.user_type == 2) {
          setUser(response.data);
        } else {
          setUser(null);
        }
      })
      .catch((error) => {
        setUser(null);

        return error;
      });
  }, []);

  const refreshWebSocket = useCallback((user_id) => {
    let socket;
    socket = io(apiUrl);
    socket.on("join", ({ payload }, callBack) => {
      if (payload) {
        return axios
          .get(`${apiUrl}/v1/comet-chat/unread-count/${user_id}`)
          .then((response) => {
            if (response.data) {
              setUnreadCount(response.data.totalUnreadCount);
            } else {
              setUnreadCount(0);
            }
          })
          .catch((error) => {
            setUnreadCount(0);

            return error;
          });
      }
    });
  }, []);

  const refreshTokens = useCallback(() => {
    console.log("workingggg ------");
    return axios
      .post(`${apiUrl}/v1/auth/refresh-tokens`, {})
      .then((response) => {
        if (
          response.data.user?.user_type == 1 ||
          response.data.user?.user_type == 2
        ) {
          setAccessToken(response.data.token);
          setUser(response.data.user);
          setIsProfileFreezed(response.data.user?.isFreezed);
          setSignup(response.data.user);
          // OGTags(response.data.user.user_id)
          GetSignUpDependency();
          return;
        } else {
          setUser(null);
          setSignup(null);
          setAccessToken(null);
        }
        return response;
      })
      .catch((error) => {
        setUser(null);
        setSignup(null);
        setAccessToken(null);
        return error;
      });
  }, []);
 
  const startSilentRefresh = useCallback(() => {
    if (accessToken) {
      const tokenExpires = moment(accessToken.expires);
      const tokenMaxAge = tokenExpires.diff(moment().add(1, "minutes"));
      setTimeout(() => {
        refreshTokens();
      }, tokenMaxAge);
    }
  }, [accessToken, refreshTokens]);

  const checkNewNotification = (id) => {
    notificationService.getLastThreeNotificationByid(id).then((resp) => {
      if (resp?.data?.filter((each) => each?.status === false)?.length) {
        setIsNewNotificationTiggered(true);
      }
    });
  };

  const syncLogout = (event) => {
    if (event.key === "logout") {
      setAccessToken(null);
      setUser(null);
      setIsProfileFreezed(false);
    }
  };

  useEffect(() => {
    const interceptorId = axios.interceptors.request.use(
      (config) => {
        config.withCredentials = true;
        config.credentials = "include";
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(interceptorId);
    };
  }, [accessToken]);

  useEffect(() => {
    refreshTokens().then((response) => {
      setLoaded(true);
    });
  }, [refreshTokens]);

  useEffect(() => {
    startSilentRefresh();
  }, [accessToken, startSilentRefresh]);

  useEffect(() => {
    window.addEventListener("storage", syncLogout);
    return function cleanup() {
      window.removeEventListener("storage", syncLogout);
    };
  }, []);
  // freeze profile popup

  const value = useMemo(() => {
    const register = (username, email, password) => {
      return axios
        .post(`${apiUrl}/v1/auth/register`, {
          name: username,
          email: email,
          password: password,
        })
        .then((response) => {
          setAccessToken(response.data.token);
          setUser(response.data.user);
          // setSignup(response.data.user)
          startSilentRefresh();
        });
    };

    const login = (email, password) => {
      return axios
        .post(`${apiUrl}/v1/auth/login`, {
          email: email,
          password: password,
        })
        .then((response) => {
          window.localStorage.setItem("logout", null);
          setAccessToken(response.data.token);
          setUser(response.data.user);
          startSilentRefresh();
        });
    };

    const oAuthCreateAccount = (user) => {
      return axios.post(`${apiUrl}/v1/talenter`, user).then((response) => {
        setAccessToken(response.data.token);
        setUser(response.data.user);
        startSilentRefresh();
      });
    };

    const updateUser = () => {
      return axios.get(`${apiUrl}/v1/user/${user.user_id}`).then((response) => {
        if (response) {
          setUser(response.data);
          setIsProfileFreezed(response.data?.isFreezed);
        }
      });
    };

    const githubAuth = () => {
      window.location.href = `${apiUrl}/v1/auth/github`;
    };

    const logout = async () => {
      let payload = { user_id: user?.user_id, last_login_on: moment() };
      window.localStorage.setItem("logout", moment());
      userServices.userProfile(payload, user?.user_id).then((res) => {});
      setAccessToken(null);
      setUser(null);
      setIsProfileFreezed(false);
      return axios
        .post(`${apiUrl}/v1/auth/logout`, {})
        .then((response) => {
          localStorage.removeItem("projectId");
          // localStorage.removeItem("unreadCount")
          handleLogout();
          return response;
        })
        .catch((err) => {});
    };
    const handleLogout = () => {
      CometChat.logout().then(
        () => {
          console.log("User logged out successfully");
          // Perform any additional cleanup or redirect logic
        },
        (error) => {
          console.log("Logout failed with error:", error);
        }
      );
    };

    const forgotPassword = (email) => {
      return axios.post(`${apiUrl}/v1/auth/forgot-password`, {
        email: email,
      });
    };

    const resetPassword = (password, resetToken) => {
      return axios.post(
        `${apiUrl}/v1/auth/reset-password?token=${resetToken}`,
        {
          password: password,
        }
      );
    };

    const verifyEmail = (emailVerificationToken) => {
      return axios.post(
        `${apiUrl}/v1/auth/verify-email?token=${emailVerificationToken}`,
        {}
      );
    };

    return {
      user,
      setUser,
      setSignup,
      signup,
      register,
      login,
      githubAuth,
      logout,
      forgotPassword,
      resetPassword,
      verifyEmail,
      oAuthCreateAccount,
      startSilentRefresh,
      setAccessToken,
      updateUser,
      isSignUpPopup,
      setIsSignUpPopup,
      setIsSignInPopup,
      isSignInPopup,
      GetSignUpDependency,
      dependencyData,
      setLoaded,
      isJobapply,
      setIsJobapply,
      setIsProfileFreezed,
      isProfileFreezed,
      setSignInTabs,
      signInTabs,
      isLoaded,
      setIsNewNotificationTiggered,
      isNewNotificationTiggered,
      checkNewNotification,
      accessToken,
      refreshTokens,
      refreshEditPage,
      refreshWebSocket,
      refreshAvailableDates,
      setRefreshAvailableDates,
      unreadCount,
      setUnreadCount,
    };
  }, [
    user,
    refreshAvailableDates,
    startSilentRefresh,
    signup,
    isSignUpPopup,
    isSignInPopup,
    dependencyData,
    isProfileFreezed,
    signInTabs,
    isLoaded,
    isNewNotificationTiggered,
    unreadCount,
    setUnreadCount,
  ]);

  if (
    !isLoaded &&
    window.location.pathname !== "/talents" &&
    window.location.pathname !== "/"
  ) {
    return <ThemedSuspense />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
