import axios from 'axios'
import { config } from '../../assets/config/config'

const apiUrl = config.api.url

const GetSimilarTalents = (user_id, talent_type, gender, min_age, max_age) => {
	return axios.get(`${apiUrl}/v1/user/user-details/talent-simillar-profile?userId=${user_id}&talentType=${talent_type}&gender=${gender}&age=${min_age}`).then((res) => { return res.data })
}

export const simarTalentService = {
    GetSimilarTalents
}