import React, { useContext } from "react";
// import { SidebarContext } from '../context/SidebarContext'
import { Link } from "react-router-dom";
import Logo from "../assets/img/Logo.svg";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { FaXTwitter } from "react-icons/fa6";
import {
  InstagramIcon,
  FacebookIcon2,
  YouTubeIcon,
  TiwtterIcon2,
  Linkedin,
} from "../icons";



function Footer() {
  const { user } = useContext(AuthContext);

  const location = useLocation();

  if (location.pathname === "/app/dashboard") {
    return <></>;
  }

  return (
    <footer className="common_footer_section">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap overflow-hidden sm:-mx-2">
          <div className="w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5">
            <div className="footer_left_item logomove">
              <img src={Logo} alt="CastingBell" />
              <label className="webhidden">
                © 2023 Casting Bell. All rights reserved
              </label>
            </div>
          </div>

          <div className="w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5">
            <div className="footer_left_item footer_leftpadding">
              <p className="heading">Product</p>
              <ul>
                {user ? (
                  <li>
                    <Link
                      to={
                        user.user_type === 1
                          ? "/talents#how-does-it-work"
                          : "/recruiters#how-does-it-work"
                      }
                    >
                      How does it work?
                    </Link>
                  </li>
                ) : (
                  <li>
                    <Link
                      to={
                        location.pathname.includes("/talent") ||
                        location.pathname === "/"
                          ? "/talents#how-does-it-work"
                          : location.pathname !== "/talents" &&
                            location.pathname !== "/find-jobs"
                          ? "/recruiters#how-does-it-work"
                          : "/talents#how-does-it-work"
                      }
                    >
                      How does it work?
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/find-jobs" onClick={() => window.scrollTo(0, 0)}>
                    Find Jobs
                  </Link>
                </li>
                <li>
                  <Link to="/talents" onClick={() => window.scrollTo(0, 0)}>
                    For Talents
                  </Link>
                </li>
                <li>
                  <Link to="/recruiters" onClick={() => window.scrollTo(0, 0)}>
                    For Recruiters
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5">
            <div className="footer_left_item footer_leftpadding">
              <p className="heading">Legal</p>
              <ul>
                <li>
                  <Link to="/view-cms/terms-and-condition">Terms of Use</Link>
                </li>
                <li>
                  <Link to="/view-cms/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5">
            <div className="footer_left_item">
              <p className="heading">Company</p>
              <ul>
                <li>
                  <Link to="/about/company">About us</Link>
                </li>
                <li>
                  <Link to="/about/contact-us">Contact us</Link>
                </li>
                {/* <li><Link  to="your-link">Partner with us</Link></li>
    <li><Link  to="your-link">Partner’s login</Link></li> */}
              </ul>
            </div>
          </div>

          <div className="w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5">
            <div className="footer_social_media">
              <p>Follow us</p>
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/CastingBell"
                    target="_blank"
                  >
                    <FacebookIcon2 aria-hidden="true" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UC1H-04kUh-NQK_gehl0Y3wg"
                    target="_blank"
                  >
                    <YouTubeIcon aria-hidden="true" />
                  </a>
                </li>
                <li className="Latest_X">
                  <a href="https://twitter.com/Castingbell_com" target="_blank" >
                    <FaXTwitter aria-hidden="true" />
            

          
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/castingbellindia"
                    target="_blank"
                  >
                    {" "}
                    <InstagramIcon aria-hidden="true" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/castingbell"
                    target="_blank"
                  >
                    <Linkedin aria-hidden="true" />
                  </a>
                </li>
              </ul>
              <label className="mobileopen">
                © 2023 Casting Bell. All rights reserved
              </label>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
