import axios from "axios";
import { config } from "../../assets/config/config";

const apiUrl = config.api.url;

const getNotificationByid = (id) => {
  return axios.get(`${apiUrl}/v1/notification?user_id=${id}`);
};
const getLastThreeNotificationByid = (id) => {
  return axios.get(
    `${apiUrl}/v1/notification/last-three-notification?user_id=${id}`
  );
};
const notificationViewed = (id) => {
  return axios.patch(`${apiUrl}/v1/notification/${id}`, { status: true });
};
const mobileUpatedNotification = (id, message) => {
  return axios.post(`${apiUrl}/v1/notification`, {
    user_id: id,
    message: message,
    notification_type: 1,
  });
};
const recruiterMobileUpatedNotification = (id, message) => {
  return axios.post(`${apiUrl}/v1/notification`, {
    user_id: id,
    message: message,
    notification_type: 2,
  });
};
const createNotification = (payload) => {
  return axios.post(`${apiUrl}/v1/notification`, payload);
};
const searchFilterNotification = (userId, searchData) => {
  return axios.get(
    `${apiUrl}/v1/notification/search-notification?user_id=${userId}&message=${searchData}`
  );
};
const setReadNotificationById = (id) => {
  return axios.patch(`${apiUrl}/v1/notification/update-notification/${id}`, {
    status: true,
  });
};

const getNotificationCountByuserID = (id) => {
  return axios.get(
    `${apiUrl}/v1/notification/get-count?notification_type=1&user_id=${id}`
  );
};
const updateNotificationCount = (id) => {
  return axios.patch(
    `${apiUrl}/v1/notification/update-notification-user/${id}`,
    { isViewed: true }
  );
};

const updateavailability = (id, payload) => {
  console.log("3453", payload);
  return axios.patch(`${apiUrl}/v1/unlock-available-calander/${id}`, payload);
};

const updateAvailabilityButton = (id, payload) => {
  console.log("3453", payload);
  return axios.patch(
    `${apiUrl}/v1/notification/update-notification-availability/${id}`,
    payload
  );
};

const updateUser = (user_id, payload) => {
  return axios.patch(`${apiUrl}/v1/user/${user_id}`, payload).then((res) => {
    return res.data;
  });
};

const getUserData = (user_id) => {
  return axios.get(`${apiUrl}/v1/user/${user_id}`);
};

export const notificationService = {
  getNotificationByid,
  getLastThreeNotificationByid,
  notificationViewed,
  mobileUpatedNotification,
  createNotification,
  searchFilterNotification,
  setReadNotificationById,
  getNotificationCountByuserID,
  updateNotificationCount,
  recruiterMobileUpatedNotification,
  updateavailability,
  updateAvailabilityButton,
  updateUser,
  getUserData,
};
